import React, { useState, createContext, useRef, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";

const ThemeContext = createContext({
  layout: { current: null },
  width: 0,
  height: 0,
  isHoveringCircleActive: false,
  setIsHoveringCircleActive: () => {},
  isFirstTime: false,
  setIsFirstTime: () => {},
  mobileMenuState: false,
  setMobileMenuState: () => {},
  cursorVariant: "default",
  setCursorVariant: () => {},
  setIsOverHalf: () => {},
  isOverHalf: false,
});

const ThemeProvider = ({ children }) => {
  const layout = useRef(null);
  const { width, height } = useWindowSize();

  const [isFirstTime, setIsFirstTime] = useState(false);
  useEffect(() => {
    setIsFirstTime(sessionStorage.getItem("isFirstTime") === null);
  }, []);
  useEffect(() => {
    if (isFirstTime) {
      sessionStorage.setItem("isFirstTime", "false");
    }
  }, [isFirstTime]);

  const [isHoveringCircleActive, setIsHoveringCircleActive] = useState(false);
  const [mobileMenuState, setMobileMenuState] = useState(false);
  const [cursorVariant, setCursorVariant] = useState("default");
  const [isOverHalf, setIsOverHalf] = useState(false);

  return (
    <ThemeContext.Provider
      value={{
        layout,
        width,
        height,
        isHoveringCircleActive,
        setIsHoveringCircleActive,
        isFirstTime,
        setIsFirstTime,
        mobileMenuState,
        setMobileMenuState,
        cursorVariant,
        setCursorVariant,
        setIsOverHalf,
        isOverHalf,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
