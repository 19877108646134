import React, { useContext } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "./context/theme-context";

const animation = {
  default: {
    rotate: 0,
    opacity: 1,
  },
  top: {
    rotate: -45,
    y: "480%",
  },
  bottom: {
    rotate: 45,
    y: "-480%",
  },
  center: {
    opacity: 0,
  },
};
const Hamburger = () => {
  const { mobileMenuState, setMobileMenuState } = useContext(ThemeContext);
  return (
    <button
      className="flex flex-col justify-between gap-y-2 relative z-[999]"
      onClick={() => setMobileMenuState((prev) => !prev)}
    >
      {[0, 1, 2].map((item, index) => {
        return (
          <motion.div
            key={`mobile-menu-${index}`}
            variants={animation}
            initial="default"
            animate={
              mobileMenuState && item === 0
                ? "top"
                : mobileMenuState && item === 2
                ? "bottom"
                : mobileMenuState && item === 1
                ? "center"
                : !mobileMenuState && "default"
            }
            transition={{ type: "tween" }}
            className="bg-black w-[37px] h-[2px]"
          />
        );
      })}
    </button>
  );
};

export default Hamburger;
