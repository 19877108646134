import { useStaticQuery, graphql } from "gatsby";

export const useWpInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      home: wpPage(slug: { eq: "home" }) {
        id
      }
      about: wpPage(slug: { eq: "about" }) {
        id
        generalInfo {
          shortText
          aboutText
          offerings {
            content
            groupLabel
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AVIF, WEBP, AUTO], quality: 100)
              }
            }
          }
        }
      }
    }
  `);

  return data;
};
