import React, { useContext } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "./context/theme-context";

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
const CreditsBar = () => {
  const { width } = useContext(ThemeContext);
  return (
    <motion.div
      variants={fade}
      initial="initial"
      animate="animate"
      exit="exit"
      className="mb-[3px] normal-case w-full font-sans leading-5 tracking-normal text-gray-500 grid grid-cols-1 sm:grid-cols-12 lg:grid-cols-20 px-0"
    >
      <div className="text-xs sm:col-start-2 sm:col-span-10 lg:col-start-4 lg:col-span-14">
        Graphic and website design by{" "}
        <a
          href="http://www.salina.design/"
          className="underline hover:no-underline"
          target="_blank"
          rel="noreferrer"
        >
          Salina
        </a>
        {width && width < 568 ? <br /> : ","} development by{" "}
        <a
          href="mailto:studio@giammarcogaudenzi.com"
          className="underline hover:no-underline"
        >
          Gaudenzi & Gabellini
        </a>
      </div>
    </motion.div>
  );
};
export default CreditsBar;
