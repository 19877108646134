import React, { useContext } from "react";
import { useWpInfo } from "../hooks/useWpInfo";
import { motion } from "framer-motion";
import { ThemeContext } from "./context/theme-context";

const SplashMobile = () => {
  const { setIsFirstTime } = useContext(ThemeContext);

  const { about } = useWpInfo();
  const aboutText = about?.generalInfo?.shortText;
  const words = aboutText.split(/\s|,\s/);
  const adjectives = words.filter((word) => {
    return (
      word === "director" ||
      word === "writer" ||
      word === "producer" ||
      word === "commissioner"
    );
  });

  const sentenceWithoutAdjectives = words
    .filter((word) => !adjectives.includes(word))
    .join(" ");

  const adjectiveColors = {
    director: "#F27542",
    writer: "#CEC945",
    producer: "#EDB0C4",
    commissioner: "#47B0B0",
  };

  return (
    <motion.div
      variants={fade}
      initial="initial"
      animate="animate"
      exit="exit"
      onClick={() => setIsFirstTime(false)}
      className="hover:cursor-pointer bg-gray-100 fixed top-0 left-0 w-full z-[2] flex justify-center items-center h-safe-screen font-serif"
    >
      <div className="px-2 absolute inset-0 grid grid-cols-10 md:grid-cols-20 text-md sm:text-2xl md:text-4xl break-word">
        <div className="col-span-10 md:col-start-3 md:col-span-16 flex items-center">
          <div className="text-center inline-block w-[100%]">
            <span className="text-xl md:text-5xl lowercase">
              {sentenceWithoutAdjectives}
            </span>

            {adjectives.map((adjective, i) => {
              let comma = ",";
              if (i === adjectives.length - 1) {
                comma = "";
              }

              return (
                <div key={i} className="inline-block lowercase">
                  &nbsp;
                  <motion.span
                    initial={{ color: "#151515" }}
                    animate={{ color: adjectiveColors[adjective] }}
                    transition={{
                      delay: 0.8 + i * 0.5,
                    }}
                    className="text-xl md:text-5xl"
                    onAnimationComplete={() => {
                      if (i === adjectives.length - 1) setIsFirstTime(false);
                    }}
                  >
                    {adjective}
                    <span className="text-black text-xl md:text-5xl">
                      {comma}
                    </span>
                  </motion.span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SplashMobile;

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.7,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
};
