import React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { useWpInfo } from "../hooks/useWpInfo";

const MobileMenu = () => {
  const { about } = useWpInfo();

  return (
    <motion.div
      className="antialiased  pt-[70px] fixed top-0 left-0 w-screen h-[100%] bg-white z-[4] px-8 font-serif text-center flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-2xl md:text-4xl flex flex-col justify-start h-min">
        {about.generalInfo.shortText}
      </div>
      <div className="font-sans leading-5 tracking-wider uppercase text-xl flex flex-col gap-y-12 md:gap-y-20 justify-center h-[100%]">
        <Link to="/" activeClassName="underline">
          Home
        </Link>
        <Link to="/archive/" activeClassName="underline">
          List
        </Link>
        <Link to="/about/" activeClassName="underline">
          About
        </Link>
        <Link to="/contacts/" activeClassName="underline">
          Contacts
        </Link>
      </div>
    </motion.div>
  );
};

export default MobileMenu;
