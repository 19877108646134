import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import { ThemeContext } from "./context/theme-context";
import MobileMenu from "./mobile-menu";
import Hamburger from "./hamburger";
import Splash from "./splash";
import SplashMobile from "./splash-mobile";
import CreditsBar from "./credits-bar";
import useMouse from "@react-hook/mouse-position";

import { isMobile } from "react-device-detect";

import "../styles/cursor.scss";

const Layout = ({ children, location }) => {
  const {
    layout,
    isFirstTime,
    width,
    mobileMenuState,
    setMobileMenuState,
    cursorVariant,
    setCursorVariant,
    isHoveringCircleActive,
    setIsOverHalf,
  } = useContext(ThemeContext);

  useEffect(() => {
    setMobileMenuState(false);
  }, [location.pathname, setMobileMenuState]);

  useEffect(() => {
    setMobileMenuState(false);
  }, [width, setMobileMenuState]);

  let mouseXPosition = 0;
  let mouseYPosition = 0;

  const mouse = useMouse(layout, {
    enterDelay: 100,
    leaveDelay: 100,
  });
  if (mouse.x !== null) {
    mouseXPosition = mouse.clientX;
    mouseXPosition > width / 2 ? setIsOverHalf(true) : setIsOverHalf(false);
  }

  if (mouse.y !== null) {
    mouseYPosition = mouse.clientY;
  }

  const variants = {
    default: {
      backgroundColor: "#1e91d6",
      x: mouseXPosition - 90,
      y: mouseYPosition - 90,
    },
    director: {
      backgroundColor: "#F27542",
      x: mouseXPosition - 90,
      y: mouseYPosition - 90,
    },
    writer: {
      backgroundColor: "#CEC945",
      x: mouseXPosition - 90,
      y: mouseYPosition - 90,
    },
    commissioner: {
      backgroundColor: "#47B0B0",
      x: mouseXPosition - 90,
      y: mouseYPosition - 90,
    },
    producer: {
      backgroundColor: "#EDB0C4",
      x: mouseXPosition - 90,
      y: mouseYPosition - 90,
    },
  };

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 28,
    mass: 0.6,
  };

  useEffect(() => {
    const colors = ["director", "writer", "commissioner", "producer"];
    let intervalId;
    if (location.pathname === "/about/") {
      let counter = 0;
      intervalId = setInterval(() => {
        setCursorVariant(colors[counter % colors.length]);
        counter++;
      }, 1500);
    }

    return () => {
      setCursorVariant("default");
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [location.pathname, setCursorVariant]);

  return (
    <>
      <AnimatePresence>{mobileMenuState && <MobileMenu />}</AnimatePresence>
      <motion.div
        className="min-safe-h-screen relative text-slate-950 antialiased max-w-screen overflow-hidden bg-white"
        ref={layout}
      >
        {!isMobile && location.pathname !== "/" && isHoveringCircleActive && (
          <motion.div
            variants={variants}
            className="circle"
            animate={cursorVariant}
            transition={spring}
          />
        )}

        <AnimatePresence>
          {width
            ? !isFirstTime && (
                <motion.header
                  variants={fade}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="fixed top-0 left-0 w-full z-[4] py-2 px-8 h-[70px] flex items-center flex-row-reverse md:flex-row uppercase text-sm"
                >
                  {width ? (
                    width > 768 ? (
                      <Link
                        to={location.pathname === "/" ? "/archive/" : "/"}
                        className="hover:underline font-sans leading-5 tracking-wider"
                      >
                        {location.pathname === "/" ? "View as a list" : "Home"}
                      </Link>
                    ) : (
                      <Hamburger />
                    )
                  ) : (
                    ""
                  )}
                </motion.header>
              )
            : ""}
        </AnimatePresence>
        <AnimatePresence>
          {width ? (
            isFirstTime ? (
              width > 768 ? (
                <Splash />
              ) : (
                <SplashMobile />
              )
            ) : (
              children
            )
          ) : (
            ""
          )}
        </AnimatePresence>
        <AnimatePresence>
          {width && width > 768 && !isFirstTime && (
            <motion.footer
              variants={fade}
              initial="initial"
              animate="animate"
              exit="exit"
              className="fixed bottom-4 left-0 w-[calc(100%-4em)] z-[4] py-2 mx-8 flex items-center flex-row justify-between uppercase text-sm font-sans leading-5 tracking-wider"
            >
              <Link
                to="/about/"
                className="hover:underline absolute bottom-[10px] left-0"
                activeClassName="underline"
              >
                about
              </Link>
              <Link
                to="/contacts/"
                className="hover:underline absolute bottom-[10px] right-0"
                activeClassName="underline"
              >
                contacts
              </Link>
              <AnimatePresence>
                {location.pathname === "/contacts/" && !isFirstTime && (
                  <CreditsBar />
                )}
              </AnimatePresence>
            </motion.footer>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default Layout;

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
