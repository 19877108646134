import React, { useContext } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "./context/theme-context";

const DURATION = 0.5;

const TypoSplash = () => {
  const { setIsFirstTime } = useContext(ThemeContext);

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 885.4 35.56"
      className="w-full"
    >
      <defs>
        <style>
          {`.cls-1 {
            stroke-width: 0px;
          }`}
        </style>
      </defs>
      <motion.g
        id="director"
        animate={{ fill: ["#000000", "#F27542"] }}
        transition={{
          duration: DURATION,
          delay: 0.8 + 0 * 0.5,
        }}
      >
        <path
          className="cls-1"
          d="m247.67,27.43h-5.56v-1.37c0-.53.02-1,.07-1.41.05-.41.17-.89.36-1.44l-.18-.11c-.53,1.56-1.31,2.75-2.33,3.56-1.02.8-2.27,1.21-3.74,1.21s-2.64-.37-3.72-1.1c-1.08-.73-1.92-1.74-2.51-3.01-.59-1.27-.88-2.72-.88-4.33,0-1.97.38-3.72,1.16-5.25.77-1.53,1.85-2.72,3.23-3.57,1.38-.85,2.94-1.28,4.68-1.28,1.49,0,2.69.31,3.61.94V3.83c0-.63-.11-1.07-.34-1.34-.23-.26-.63-.4-1.21-.4-.43,0-1.17.1-2.2.29v-.79l5.92-1.59h.9v24.69c0,.63.19,1.05.58,1.28.38.23,1.11.41,2.17.56v.9Zm-5.81-15.2c-.72-1.01-1.95-1.52-3.68-1.52s-3.11.67-4.13,2.02c-1.02,1.35-1.53,3.15-1.53,5.41s.46,3.87,1.39,5.11c.93,1.24,2.21,1.86,3.85,1.86.89,0,1.7-.21,2.42-.63.72-.42,1.29-1,1.7-1.75v-10.5Z"
        />
        <path
          className="cls-1"
          d="m258.82,27.43h-9.06v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.32c0-.63-.13-1.06-.38-1.32s-.69-.38-1.32-.38c-.36,0-.83.04-1.41.11v-.9l5.27-1.41h.9v14.22c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Zm-6.46-21.08c-.46-.46-.68-1.02-.68-1.7s.23-1.24.68-1.7c.46-.46,1.02-.68,1.7-.68s1.24.23,1.7.68c.46.46.69,1.02.69,1.7s-.23,1.24-.69,1.7c-.46.46-1.02.69-1.7.69s-1.24-.23-1.7-.69Z"
        />
        <path
          className="cls-1"
          d="m273.19,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
        <path
          className="cls-1"
          d="m289.07,22.27l.87.43c-.39,1.47-1.18,2.7-2.38,3.68-1.2.99-2.68,1.48-4.44,1.48-2.5,0-4.45-.82-5.85-2.47-1.4-1.65-2.09-3.82-2.09-6.52,0-1.83.31-3.46.94-4.91s1.53-2.58,2.73-3.41c1.19-.83,2.62-1.25,4.28-1.25,2.21,0,3.9.7,5.07,2.09,1.17,1.4,1.75,3.25,1.75,5.56h-12.06c.1,2.38.7,4.25,1.82,5.61,1.12,1.36,2.68,2.04,4.68,2.04,2.26,0,3.83-.78,4.69-2.35Zm-9.84-10.18c-.78.89-1.22,2.11-1.32,3.65h8.16c0-1.52-.3-2.72-.88-3.63-.59-.9-1.51-1.35-2.76-1.35-1.35,0-2.41.45-3.19,1.34Z"
        />
        <path
          className="cls-1"
          d="m296.67,26.79c-1.21-.72-2.16-1.75-2.83-3.09-.67-1.34-1.01-2.89-1.01-4.67s.33-3.38.99-4.86c.66-1.48,1.65-2.66,2.96-3.54s2.88-1.32,4.71-1.32c.99,0,1.9.07,2.73.22.83.15,1.68.41,2.54.79v5.09h-1.01c-.38-1.44-.98-2.51-1.79-3.21-.81-.7-1.9-1.05-3.27-1.05-1.61,0-2.87.53-3.77,1.59-.9,1.06-1.35,2.55-1.35,4.48,0,1.44.28,2.73.83,3.86s1.33,2,2.33,2.62c1,.61,2.16.92,3.48.92,2.07,0,3.57-.79,4.51-2.38l.83.36c-.38,1.54-1.14,2.8-2.26,3.79-1.12.99-2.59,1.48-4.42,1.48-1.59,0-2.99-.36-4.21-1.08Z"
        />
        <path
          className="cls-1"
          d="m313.02,26.71c-.66-.77-.99-1.83-.99-3.18v-11.98h-3.21v-.9c1.3-.31,2.38-.9,3.23-1.77.85-.87,1.56-1.97,2.11-3.32h.94v4.19h4.91v1.81h-4.91v10.65c0,1.18.26,2.03.79,2.56.53.53,1.18.79,1.95.79.46,0,.87-.08,1.25-.24.37-.16.78-.4,1.21-.74l.61.76c-.7.82-1.46,1.44-2.29,1.88-.83.43-1.69.65-2.58.65-1.35,0-2.35-.38-3.01-1.16Z"
        />
        <path
          className="cls-1"
          d="m326.61,26.64c-1.35-.82-2.39-1.93-3.14-3.34-.75-1.41-1.12-2.98-1.12-4.71s.37-3.3,1.12-4.71c.75-1.41,1.79-2.52,3.14-3.34,1.35-.82,2.88-1.23,4.58-1.23s3.24.41,4.58,1.23c1.35.82,2.39,1.93,3.14,3.34.75,1.41,1.12,2.98,1.12,4.71s-.37,3.3-1.12,4.71c-.75,1.41-1.79,2.52-3.14,3.34-1.35.82-2.88,1.23-4.58,1.23s-3.24-.41-4.58-1.23Zm9.75-8.05c0-5.13-1.72-7.69-5.16-7.69s-5.16,2.56-5.16,7.69,1.72,7.69,5.16,7.69,5.16-2.56,5.16-7.69Z"
        />
        <path
          className="cls-1"
          d="m354.95,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
      </motion.g>
      <motion.g
        id="writer"
        animate={{ fill: ["#000000", "#CEC945"] }}
        transition={{
          duration: DURATION,
          delay: 0.8 + 1 * 0.5,
        }}
      >
        <path
          className="cls-1"
          d="m388.73,15.2c-.1.6-.32,1.35-.69,2.24l-4.26,10.18h-.94l-6.61-15.23c-.34-.72-.65-1.19-.94-1.41-.29-.22-.77-.32-1.44-.32v-.9h8.01v.9c-.77,0-1.32.1-1.66.31-.34.2-.51.54-.51.99,0,.26.12.71.36,1.34l3.14,7.51c.24.53.4.99.47,1.39.07.4.11.94.11,1.64h.18c.07-.65.26-1.4.58-2.24l3.32-7.65-.69-1.55c-.34-.75-.64-1.22-.92-1.43-.28-.2-.75-.31-1.43-.31v-.9h8.55v.9c-1.06,0-1.77.09-2.15.27-.37.18-.56.52-.56,1.03,0,.29.1.72.29,1.3l3.14,7.69c.38.94.58,1.95.58,3.03h.18c.07-.67.26-1.42.58-2.24l3.5-8.38c.22-.55.32-1,.32-1.34,0-.55-.2-.92-.59-1.1-.4-.18-1.17-.27-2.33-.27v-.9h7.04v.9c-.63,0-1.12.13-1.48.38-.36.25-.66.69-.9,1.32l-6.28,15.27h-.94l-4.4-10.18c-.31-.7-.47-1.44-.47-2.24h-.18Z"
        />
        <path
          className="cls-1"
          d="m417.03,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
        <path
          className="cls-1"
          d="m428.37,27.43h-9.06v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.32c0-.63-.13-1.06-.38-1.32s-.69-.38-1.32-.38c-.36,0-.83.04-1.41.11v-.9l5.27-1.41h.9v14.22c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Zm-6.46-21.08c-.46-.46-.68-1.02-.68-1.7s.23-1.24.68-1.7c.46-.46,1.02-.68,1.7-.68s1.24.23,1.7.68c.46.46.69,1.02.69,1.7s-.23,1.24-.69,1.7c-.46.46-1.02.69-1.7.69s-1.24-.23-1.7-.69Z"
        />
        <path
          className="cls-1"
          d="m433.66,26.71c-.66-.77-.99-1.83-.99-3.18v-11.98h-3.21v-.9c1.3-.31,2.38-.9,3.23-1.77.85-.87,1.56-1.97,2.11-3.32h.94v4.19h4.91v1.81h-4.91v10.65c0,1.18.26,2.03.79,2.56.53.53,1.18.79,1.95.79.46,0,.87-.08,1.25-.24.37-.16.78-.4,1.21-.74l.61.76c-.7.82-1.46,1.44-2.29,1.88-.83.43-1.69.65-2.58.65-1.35,0-2.35-.38-3.01-1.16Z"
        />
        <path
          className="cls-1"
          d="m457.25,22.27l.87.43c-.39,1.47-1.18,2.7-2.38,3.68-1.2.99-2.68,1.48-4.44,1.48-2.5,0-4.45-.82-5.85-2.47-1.4-1.65-2.09-3.82-2.09-6.52,0-1.83.31-3.46.94-4.91s1.53-2.58,2.73-3.41c1.19-.83,2.62-1.25,4.28-1.25,2.21,0,3.9.7,5.07,2.09,1.17,1.4,1.75,3.25,1.75,5.56h-12.06c.1,2.38.7,4.25,1.82,5.61,1.12,1.36,2.68,2.04,4.68,2.04,2.26,0,3.83-.78,4.69-2.35Zm-9.84-10.18c-.78.89-1.22,2.11-1.32,3.65h8.16c0-1.52-.3-2.72-.88-3.63-.59-.9-1.51-1.35-2.76-1.35-1.35,0-2.41.45-3.19,1.34Z"
        />
        <path
          className="cls-1"
          d="m473.02,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
      </motion.g>
      <motion.g
        id="producer"
        animate={{ fill: ["#000000", "#EDB0C4"] }}
        transition={{
          duration: DURATION,
          delay: 0.8 + 2 * 0.5,
        }}
      >
        <path
          className="cls-1"
          d="m492.91,34.65c.87-.1,1.5-.22,1.89-.36.4-.14.68-.38.85-.7.17-.32.25-.82.25-1.5V13.65c0-.6-.13-1.03-.38-1.28-.25-.25-.67-.38-1.24-.38-.31,0-.85.02-1.62.07v-.9l5.09-1.41h.83v2.02c0,.43-.08,1.15-.25,2.17l.14.04c.46-1.44,1.25-2.58,2.36-3.41,1.12-.83,2.39-1.25,3.81-1.25s2.62.34,3.68,1.01c1.06.67,1.88,1.64,2.45,2.89s.87,2.7.87,4.33c0,1.97-.37,3.75-1.12,5.32-.75,1.58-1.78,2.8-3.11,3.68s-2.83,1.32-4.51,1.32c-1.44,0-2.76-.36-3.93-1.08v5.31c0,.67.11,1.18.33,1.52.22.34.58.58,1.08.72s1.27.25,2.31.32v.9h-9.78v-.9Zm13.95-10.27c.98-1.38,1.46-3.23,1.46-5.54,0-2.17-.43-3.83-1.28-5-.85-1.17-2.09-1.75-3.7-1.75-.99,0-1.87.21-2.64.61-.77.41-1.35.99-1.73,1.73v10.14c.6.65,1.22,1.13,1.86,1.43.64.3,1.35.45,2.15.45,1.61,0,2.91-.69,3.88-2.08Z"
        />
        <path
          className="cls-1"
          d="m526.55,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
        <path
          className="cls-1"
          d="m532.62,26.64c-1.35-.82-2.39-1.93-3.14-3.34-.75-1.41-1.12-2.98-1.12-4.71s.37-3.3,1.12-4.71c.75-1.41,1.79-2.52,3.14-3.34,1.35-.82,2.88-1.23,4.58-1.23s3.24.41,4.58,1.23c1.35.82,2.39,1.93,3.14,3.34.75,1.41,1.12,2.98,1.12,4.71s-.37,3.3-1.12,4.71c-.75,1.41-1.79,2.52-3.14,3.34-1.35.82-2.88,1.23-4.58,1.23s-3.24-.41-4.58-1.23Zm9.75-8.05c0-5.13-1.72-7.69-5.16-7.69s-5.16,2.56-5.16,7.69,1.72,7.69,5.16,7.69,5.16-2.56,5.16-7.69Z"
        />
        <path
          className="cls-1"
          d="m567.24,27.43h-5.56v-1.37c0-.53.02-1,.07-1.41.05-.41.17-.89.36-1.44l-.18-.11c-.53,1.56-1.31,2.75-2.33,3.56-1.02.8-2.27,1.21-3.74,1.21s-2.64-.37-3.72-1.1c-1.08-.73-1.92-1.74-2.51-3.01-.59-1.27-.88-2.72-.88-4.33,0-1.97.38-3.72,1.16-5.25.77-1.53,1.85-2.72,3.23-3.57,1.38-.85,2.94-1.28,4.68-1.28,1.49,0,2.69.31,3.61.94V3.83c0-.63-.11-1.07-.34-1.34-.23-.26-.63-.4-1.21-.4-.43,0-1.17.1-2.2.29v-.79l5.92-1.59h.9v24.69c0,.63.19,1.05.58,1.28.38.23,1.11.41,2.17.56v.9Zm-5.81-15.2c-.72-1.01-1.95-1.52-3.68-1.52s-3.11.67-4.13,2.02c-1.02,1.35-1.53,3.15-1.53,5.41s.46,3.87,1.39,5.11c.93,1.24,2.21,1.86,3.85,1.86.89,0,1.7-.21,2.42-.63.72-.42,1.29-1,1.7-1.75v-10.5Z"
        />
        <path
          className="cls-1"
          d="m587.95,27.43h-5.56v-1.3c0-.65.02-1.17.07-1.55.05-.38.16-.83.33-1.34l-.22-.04c-.41,1.44-1.17,2.58-2.27,3.41-1.11.83-2.31,1.25-3.61,1.25-1.64,0-2.96-.5-3.97-1.5s-1.52-2.42-1.52-4.28v-8.88c0-.51-.09-.87-.25-1.1-.17-.23-.44-.37-.81-.43s-.98-.09-1.82-.09v-.94l5.23-.9h.72v11.52c0,2.77,1.26,4.15,3.79,4.15.87,0,1.68-.25,2.45-.74s1.31-1.14,1.62-1.93v-9.53c0-.51-.08-.87-.25-1.1-.17-.23-.44-.37-.81-.43s-.98-.09-1.82-.09v-.94l5.23-.9h.72v14.94c0,.63.19,1.05.58,1.28s1.11.41,2.17.56v.9Z"
        />
        <path
          className="cls-1"
          d="m594.14,26.79c-1.21-.72-2.16-1.75-2.83-3.09-.67-1.34-1.01-2.89-1.01-4.67s.33-3.38.99-4.86c.66-1.48,1.65-2.66,2.96-3.54s2.88-1.32,4.71-1.32c.99,0,1.9.07,2.73.22.83.15,1.68.41,2.54.79v5.09h-1.01c-.38-1.44-.98-2.51-1.79-3.21-.81-.7-1.9-1.05-3.27-1.05-1.61,0-2.87.53-3.77,1.59-.9,1.06-1.35,2.55-1.35,4.48,0,1.44.28,2.73.83,3.86s1.33,2,2.33,2.62c1,.61,2.16.92,3.48.92,2.07,0,3.57-.79,4.51-2.38l.83.36c-.38,1.54-1.14,2.8-2.26,3.79-1.12.99-2.59,1.48-4.42,1.48-1.59,0-2.99-.36-4.21-1.08Z"
        />
        <path
          className="cls-1"
          d="m621.45,22.27l.87.43c-.39,1.47-1.18,2.7-2.38,3.68-1.2.99-2.68,1.48-4.44,1.48-2.5,0-4.45-.82-5.85-2.47-1.4-1.65-2.09-3.82-2.09-6.52,0-1.83.31-3.46.94-4.91s1.53-2.58,2.73-3.41c1.19-.83,2.62-1.25,4.28-1.25,2.21,0,3.9.7,5.07,2.09,1.17,1.4,1.75,3.25,1.75,5.56h-12.06c.1,2.38.7,4.25,1.82,5.61,1.12,1.36,2.68,2.04,4.68,2.04,2.26,0,3.83-.78,4.69-2.35Zm-9.84-10.18c-.78.89-1.22,2.11-1.32,3.65h8.16c0-1.52-.3-2.72-.88-3.63-.59-.9-1.51-1.35-2.76-1.35-1.35,0-2.41.45-3.19,1.34Z"
        />
        <path
          className="cls-1"
          d="m637.23,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
      </motion.g>
      <g>
        <path
          className="cls-1"
          d="m15.32,10.32c1.05.67,1.86,1.62,2.44,2.85.58,1.23.87,2.64.87,4.22,0,2.02-.4,3.83-1.21,5.41-.81,1.59-1.92,2.83-3.34,3.72s-3.04,1.34-4.87,1.34c-1.11,0-2.22-.17-3.34-.52-1.12-.35-2.11-.84-2.98-1.46V3.83c0-.67-.11-1.14-.34-1.41s-.62-.4-1.17-.4c-.36,0-.82.04-1.37.11v-.79L5.05,0h.9v10.22c0,1.2-.23,2.44-.69,3.72l.22.11c.38-1.44,1.14-2.59,2.28-3.45,1.13-.85,2.44-1.28,3.93-1.28,1.37,0,2.58.34,3.63,1.01Zm-1.41,14.02c.95-1.41,1.43-3.33,1.43-5.76,0-2.07-.43-3.67-1.3-4.8-.87-1.13-2.09-1.7-3.68-1.7-1.06,0-1.97.21-2.72.63-.76.42-1.32,1.03-1.68,1.82v9.82c.34.6.89,1.1,1.66,1.5s1.58.59,2.42.59c1.64,0,2.93-.7,3.88-2.11Z"
        />
        <path
          className="cls-1"
          d="m35.77,22.27l.87.43c-.39,1.47-1.18,2.7-2.38,3.68-1.2.99-2.68,1.48-4.44,1.48-2.5,0-4.45-.82-5.85-2.47-1.4-1.65-2.09-3.82-2.09-6.52,0-1.83.31-3.46.94-4.91s1.53-2.58,2.73-3.41c1.19-.83,2.62-1.25,4.28-1.25,2.21,0,3.9.7,5.07,2.09,1.17,1.4,1.75,3.25,1.75,5.56h-12.06c.1,2.38.7,4.25,1.82,5.61,1.12,1.36,2.68,2.04,4.68,2.04,2.26,0,3.83-.78,4.69-2.35Zm-9.84-10.18c-.78.89-1.22,2.11-1.32,3.65h8.16c0-1.52-.3-2.72-.88-3.63-.59-.9-1.51-1.35-2.76-1.35-1.35,0-2.41.45-3.19,1.34Z"
        />
        <path
          className="cls-1"
          d="m43.37,26.79c-1.21-.72-2.16-1.75-2.83-3.09-.67-1.34-1.01-2.89-1.01-4.67s.33-3.38.99-4.86c.66-1.48,1.65-2.66,2.96-3.54s2.88-1.32,4.71-1.32c.99,0,1.9.07,2.73.22.83.15,1.68.41,2.54.79v5.09h-1.01c-.38-1.44-.98-2.51-1.79-3.21-.81-.7-1.9-1.05-3.27-1.05-1.61,0-2.87.53-3.77,1.59-.9,1.06-1.35,2.55-1.35,4.48,0,1.44.28,2.73.83,3.86s1.33,2,2.33,2.62c1,.61,2.16.92,3.48.92,2.07,0,3.57-.79,4.51-2.38l.83.36c-.38,1.54-1.14,2.8-2.26,3.79-1.12.99-2.59,1.48-4.42,1.48-1.59,0-2.99-.36-4.21-1.08Z"
        />
        <path
          className="cls-1"
          d="m78.62,22.27l.87.43c-.39,1.47-1.18,2.7-2.38,3.68-1.2.99-2.68,1.48-4.44,1.48-2.5,0-4.45-.82-5.85-2.47-1.4-1.65-2.09-3.82-2.09-6.52,0-1.83.31-3.46.94-4.91s1.53-2.58,2.73-3.41c1.19-.83,2.62-1.25,4.28-1.25,2.21,0,3.9.7,5.07,2.09,1.17,1.4,1.75,3.25,1.75,5.56h-12.06c.1,2.38.7,4.25,1.82,5.61,1.12,1.36,2.68,2.04,4.68,2.04,2.26,0,3.83-.78,4.69-2.35Zm-9.84-10.18c-.78.89-1.22,2.11-1.32,3.65h8.16c0-1.52-.3-2.72-.88-3.63-.59-.9-1.51-1.35-2.76-1.35-1.35,0-2.41.45-3.19,1.34Z"
        />
        <path
          className="cls-1"
          d="m83.31,12.38c-.34-.72-.64-1.19-.9-1.41-.27-.22-.7-.32-1.3-.32v-.9h8.7v.9c-1.04,0-1.78.1-2.22.29s-.67.53-.67,1.01c0,.34.11.78.32,1.34l3.39,7.72c.34.89.54,1.88.61,2.96h.18c.07-.77.28-1.53.61-2.28l3.9-8.41c.24-.55.36-1.01.36-1.37,0-.48-.25-.81-.74-.99-.49-.18-1.28-.27-2.36-.27v-.9h6.89v.9c-.84,0-1.5.58-1.99,1.73l-6.89,15.23h-.9l-7-15.23Z"
        />
        <path
          className="cls-1"
          d="m117.9,25.02c-.55,1.85-1.7,2.78-3.43,2.78-.94,0-1.68-.29-2.24-.87s-.82-1.41-.79-2.49h-.22c-.22.92-.76,1.72-1.62,2.4-.87.69-1.95,1.03-3.25,1.03-1.56,0-2.78-.39-3.66-1.17s-1.32-1.91-1.32-3.38.5-2.57,1.5-3.45c1-.88,2.57-1.53,4.71-1.97l3.79-.76v-1.16c0-1.35-.33-2.39-.99-3.12-.66-.73-1.67-1.1-3.01-1.1-2.34,0-3.88.99-4.62,2.96l-.98-.29c.34-1.47,1.05-2.69,2.13-3.66,1.08-.97,2.61-1.46,4.58-1.46,1.81,0,3.25.53,4.33,1.59,1.08,1.06,1.62,2.66,1.62,4.8v7.91c0,1.28.48,1.91,1.44,1.91.6,0,1.07-.29,1.41-.87l.61.36Zm-6.53-2.31v-4.33l-1.98.36c-1.49.29-2.63.75-3.41,1.39-.78.64-1.17,1.53-1.17,2.69,0,.96.25,1.73.74,2.29s1.21.85,2.15.85c1.81,0,3.03-1.08,3.68-3.25Z"
        />
        <path
          className="cls-1"
          d="m140.17,27.43h-8.45v-.9c.94-.15,1.57-.37,1.89-.67s.49-.79.49-1.46v-8.19c0-1.52-.33-2.64-.99-3.38s-1.61-1.1-2.83-1.1c-2.19,0-3.68.9-4.48,2.71v9.53c0,.96.16,1.6.49,1.91s.99.53,2,.65v.9h-8.55v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.83c0-.41-.15-.71-.45-.9s-.7-.29-1.21-.29c-.24,0-.76.04-1.55.11v-.9l5.09-1.41h.83v1.98c0,.56-.1,1.3-.29,2.24l.18.04c.48-1.49,1.27-2.65,2.36-3.47,1.09-.82,2.44-1.23,4.02-1.23s2.98.51,3.97,1.52,1.48,2.61,1.48,4.8v8.34c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Z"
        />
        <path
          className="cls-1"
          d="m145.24,27.43c-1.02-.29-1.93-.77-2.72-1.44v-4.33h1.19c.34,1.56.97,2.73,1.89,3.5s2.03,1.15,3.3,1.15c1.11,0,1.97-.27,2.6-.81.63-.54.94-1.26.94-2.15,0-.75-.2-1.35-.61-1.81-.41-.46-1.05-.83-1.91-1.12l-3.14-1.01c-1.37-.43-2.41-1.08-3.11-1.93-.7-.85-1.05-1.85-1.05-2.98,0-1.56.61-2.82,1.82-3.77,1.21-.95,2.77-1.43,4.67-1.43.87,0,1.76.1,2.69.31s1.74.46,2.44.78v3.97h-1.23c-.22-1.01-.71-1.86-1.48-2.56-.77-.7-1.77-1.05-3-1.05-.96,0-1.74.23-2.35.69-.6.46-.9,1.07-.9,1.84,0,.72.19,1.32.56,1.81s.97.87,1.79,1.15l3.65,1.26c2.5.87,3.75,2.48,3.75,4.84,0,1.68-.61,3.03-1.84,4.02-1.23,1-2.85,1.5-4.87,1.5-1.04,0-2.06-.14-3.09-.43Z"
        />
        <path
          className="cls-1"
          d="m175.4,27.43h-9.06v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.32c0-.63-.13-1.06-.38-1.32s-.69-.38-1.32-.38c-.36,0-.83.04-1.41.11v-.9l5.27-1.41h.9v14.22c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Zm-6.46-21.08c-.46-.46-.68-1.02-.68-1.7s.23-1.24.68-1.7c.46-.46,1.02-.68,1.7-.68s1.24.23,1.7.68c.46.46.69,1.02.69,1.7s-.23,1.24-.69,1.7c-.46.46-1.02.69-1.7.69s-1.24-.23-1.7-.69Z"
        />
        <path
          className="cls-1"
          d="m180.65,27.43c-1.02-.29-1.93-.77-2.72-1.44v-4.33h1.19c.34,1.56.97,2.73,1.89,3.5s2.03,1.15,3.3,1.15c1.11,0,1.97-.27,2.6-.81.63-.54.94-1.26.94-2.15,0-.75-.2-1.35-.61-1.81-.41-.46-1.05-.83-1.91-1.12l-3.14-1.01c-1.37-.43-2.41-1.08-3.11-1.93-.7-.85-1.05-1.85-1.05-2.98,0-1.56.61-2.82,1.82-3.77,1.21-.95,2.77-1.43,4.67-1.43.87,0,1.76.1,2.69.31s1.74.46,2.44.78v3.97h-1.23c-.22-1.01-.71-1.86-1.48-2.56-.77-.7-1.77-1.05-3-1.05-.96,0-1.74.23-2.35.69-.6.46-.9,1.07-.9,1.84,0,.72.19,1.32.56,1.81s.97.87,1.79,1.15l3.65,1.26c2.5.87,3.75,2.48,3.75,4.84,0,1.68-.61,3.03-1.84,4.02-1.23,1-2.85,1.5-4.87,1.5-1.04,0-2.06-.14-3.09-.43Z"
        />
        <path
          className="cls-1"
          d="m218.72,25.02c-.55,1.85-1.7,2.78-3.43,2.78-.94,0-1.68-.29-2.24-.87s-.82-1.41-.79-2.49h-.22c-.22.92-.76,1.72-1.62,2.4-.87.69-1.95,1.03-3.25,1.03-1.56,0-2.78-.39-3.66-1.17s-1.32-1.91-1.32-3.38.5-2.57,1.5-3.45c1-.88,2.57-1.53,4.71-1.97l3.79-.76v-1.16c0-1.35-.33-2.39-.99-3.12-.66-.73-1.67-1.1-3.01-1.1-2.34,0-3.88.99-4.62,2.96l-.98-.29c.34-1.47,1.05-2.69,2.13-3.66,1.08-.97,2.61-1.46,4.58-1.46,1.81,0,3.25.53,4.33,1.59,1.08,1.06,1.62,2.66,1.62,4.8v7.91c0,1.28.48,1.91,1.44,1.91.6,0,1.07-.29,1.41-.87l.61.36Zm-6.53-2.31v-4.33l-1.98.36c-1.49.29-2.63.75-3.41,1.39-.78.64-1.17,1.53-1.17,2.69,0,.96.25,1.73.74,2.29s1.21.85,2.15.85c1.81,0,3.03-1.08,3.68-3.25Z"
        />
        <path
          className="cls-1"
          d="m357.98,32.16c1.06-.26,1.81-.7,2.24-1.32.43-.61.65-1.26.65-1.93,0-.53-.15-.9-.45-1.1s-.75-.39-1.35-.56c-1.42-.41-2.13-1.21-2.13-2.42,0-.7.23-1.27.7-1.72.47-.45,1.08-.67,1.82-.67.99,0,1.8.4,2.44,1.19.64.79.96,1.84.96,3.14,0,1.78-.48,3.18-1.43,4.21-.95,1.02-2.05,1.65-3.3,1.89l-.15-.72Z"
        />
        <path
          className="cls-1"
          d="m476.05,32.16c1.06-.26,1.81-.7,2.24-1.32.43-.61.65-1.26.65-1.93,0-.53-.15-.9-.45-1.1s-.75-.39-1.35-.56c-1.42-.41-2.13-1.21-2.13-2.42,0-.7.23-1.27.7-1.72.47-.45,1.08-.67,1.82-.67.99,0,1.8.4,2.44,1.19.64.79.96,1.84.96,3.14,0,1.78-.48,3.18-1.43,4.21-.95,1.02-2.05,1.65-3.3,1.89l-.15-.72Z"
        />
        <path
          className="cls-1"
          d="m640.26,32.16c1.06-.26,1.81-.7,2.24-1.32.43-.61.65-1.26.65-1.93,0-.53-.15-.9-.45-1.1s-.75-.39-1.35-.56c-1.42-.41-2.13-1.21-2.13-2.42,0-.7.23-1.27.7-1.72.47-.45,1.08-.67,1.82-.67.99,0,1.8.4,2.44,1.19.64.79.96,1.84.96,3.14,0,1.78-.48,3.18-1.43,4.21-.95,1.02-2.05,1.65-3.3,1.89l-.15-.72Z"
        />
      </g>
      <motion.g
        id="commissioner"
        animate={{ fill: ["#000000", "#47B0B0"] }}
        transition={{
          duration: DURATION,
          delay: 0.8 + 3 * 0.5,
        }}
        onAnimationComplete={() => {
          setIsFirstTime(false);
        }}
      >
        <path
          className="cls-1"
          d="m660.89,26.79c-1.21-.72-2.16-1.75-2.83-3.09-.67-1.34-1.01-2.89-1.01-4.67s.33-3.38.99-4.86c.66-1.48,1.65-2.66,2.96-3.54s2.88-1.32,4.71-1.32c.99,0,1.9.07,2.73.22.83.15,1.68.41,2.54.79v5.09h-1.01c-.38-1.44-.98-2.51-1.79-3.21-.81-.7-1.9-1.05-3.27-1.05-1.61,0-2.87.53-3.77,1.59-.9,1.06-1.35,2.55-1.35,4.48,0,1.44.28,2.73.83,3.86s1.33,2,2.33,2.62c1,.61,2.16.92,3.48.92,2.07,0,3.57-.79,4.51-2.38l.83.36c-.38,1.54-1.14,2.8-2.26,3.79-1.12.99-2.59,1.48-4.42,1.48-1.59,0-2.99-.36-4.21-1.08Z"
        />
        <path
          className="cls-1"
          d="m678.56,26.64c-1.35-.82-2.39-1.93-3.14-3.34-.75-1.41-1.12-2.98-1.12-4.71s.37-3.3,1.12-4.71c.75-1.41,1.79-2.52,3.14-3.34,1.35-.82,2.88-1.23,4.58-1.23s3.24.41,4.58,1.23c1.35.82,2.39,1.93,3.14,3.34.75,1.41,1.12,2.98,1.12,4.71s-.37,3.3-1.12,4.71c-.75,1.41-1.79,2.52-3.14,3.34-1.35.82-2.88,1.23-4.58,1.23s-3.24-.41-4.58-1.23Zm9.75-8.05c0-5.13-1.72-7.69-5.16-7.69s-5.16,2.56-5.16,7.69,1.72,7.69,5.16,7.69,5.16-2.56,5.16-7.69Z"
        />
        <path
          className="cls-1"
          d="m724.87,27.43h-8.45v-.9c.91-.15,1.54-.37,1.88-.67.34-.3.51-.79.51-1.46v-8.3c0-2.91-1.18-4.37-3.54-4.37-.96,0-1.78.24-2.45.71-.67.47-1.17,1.16-1.48,2.08v9.46c0,.94.16,1.57.49,1.89.33.32.99.55,2,.67v.9h-7.94v-.9c.91-.15,1.54-.37,1.88-.67.34-.3.51-.79.51-1.46v-8.3c0-2.91-1.17-4.37-3.5-4.37-.94,0-1.73.22-2.36.65s-1.16,1.11-1.57,2.02v9.96c0,.7.18,1.2.54,1.52.36.31,1,.53,1.91.65v.9h-8.52v-.9c.87-.07,1.5-.18,1.91-.33.41-.14.69-.38.85-.72.16-.34.24-.84.24-1.52v-10.32c0-.63-.13-1.06-.38-1.3-.25-.24-.73-.36-1.43-.36l-1.44.07v-.9l5.09-1.41h.83v1.98c0,.6-.09,1.36-.25,2.28l.14.04c.41-1.44,1.14-2.59,2.18-3.45,1.05-.85,2.27-1.28,3.66-1.28,1.3,0,2.38.32,3.23.96.85.64,1.43,1.56,1.71,2.76h.22c.94-2.48,2.76-3.72,5.45-3.72,1.59,0,2.83.49,3.74,1.46.9.98,1.35,2.34,1.35,4.1v9.1c0,.65.08,1.14.24,1.48.16.34.44.58.85.74.41.16,1.05.27,1.91.34v.9Z"
        />
        <path
          className="cls-1"
          d="m757.04,27.43h-8.45v-.9c.91-.15,1.54-.37,1.88-.67.34-.3.51-.79.51-1.46v-8.3c0-2.91-1.18-4.37-3.54-4.37-.96,0-1.78.24-2.45.71-.67.47-1.17,1.16-1.48,2.08v9.46c0,.94.16,1.57.49,1.89.33.32.99.55,2,.67v.9h-7.94v-.9c.91-.15,1.54-.37,1.88-.67.34-.3.51-.79.51-1.46v-8.3c0-2.91-1.17-4.37-3.5-4.37-.94,0-1.73.22-2.36.65s-1.16,1.11-1.57,2.02v9.96c0,.7.18,1.2.54,1.52.36.31,1,.53,1.91.65v.9h-8.52v-.9c.87-.07,1.5-.18,1.91-.33.41-.14.69-.38.85-.72.16-.34.24-.84.24-1.52v-10.32c0-.63-.13-1.06-.38-1.3-.25-.24-.73-.36-1.43-.36l-1.44.07v-.9l5.09-1.41h.83v1.98c0,.6-.09,1.36-.25,2.28l.14.04c.41-1.44,1.14-2.59,2.18-3.45,1.05-.85,2.27-1.28,3.66-1.28,1.3,0,2.38.32,3.23.96.85.64,1.43,1.56,1.71,2.76h.22c.94-2.48,2.76-3.72,5.45-3.72,1.59,0,2.83.49,3.74,1.46.9.98,1.35,2.34,1.35,4.1v9.1c0,.65.08,1.14.24,1.48.16.34.44.58.85.74.41.16,1.05.27,1.91.34v.9Z"
        />
        <path
          className="cls-1"
          d="m768.01,27.43h-9.06v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.32c0-.63-.13-1.06-.38-1.32s-.69-.38-1.32-.38c-.36,0-.83.04-1.41.11v-.9l5.27-1.41h.9v14.22c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Zm-6.46-21.08c-.46-.46-.68-1.02-.68-1.7s.23-1.24.68-1.7c.46-.46,1.02-.68,1.7-.68s1.24.23,1.7.68c.46.46.69,1.02.69,1.7s-.23,1.24-.69,1.7c-.46.46-1.02.69-1.7.69s-1.24-.23-1.7-.69Z"
        />
        <path
          className="cls-1"
          d="m773.26,27.43c-1.02-.29-1.93-.77-2.72-1.44v-4.33h1.19c.34,1.56.97,2.73,1.89,3.5s2.03,1.15,3.3,1.15c1.11,0,1.97-.27,2.6-.81.63-.54.94-1.26.94-2.15,0-.75-.2-1.35-.61-1.81-.41-.46-1.05-.83-1.91-1.12l-3.14-1.01c-1.37-.43-2.41-1.08-3.11-1.93-.7-.85-1.05-1.85-1.05-2.98,0-1.56.61-2.82,1.82-3.77,1.21-.95,2.77-1.43,4.67-1.43.87,0,1.76.1,2.69.31s1.74.46,2.44.78v3.97h-1.23c-.22-1.01-.71-1.86-1.48-2.56-.77-.7-1.77-1.05-3-1.05-.96,0-1.74.23-2.35.69-.6.46-.9,1.07-.9,1.84,0,.72.19,1.32.56,1.81s.97.87,1.79,1.15l3.65,1.26c2.5.87,3.75,2.48,3.75,4.84,0,1.68-.61,3.03-1.84,4.02-1.23,1-2.85,1.5-4.87,1.5-1.04,0-2.06-.14-3.09-.43Z"
        />
        <path
          className="cls-1"
          d="m788.86,27.43c-1.02-.29-1.93-.77-2.72-1.44v-4.33h1.19c.34,1.56.97,2.73,1.89,3.5s2.03,1.15,3.3,1.15c1.11,0,1.97-.27,2.6-.81.63-.54.94-1.26.94-2.15,0-.75-.2-1.35-.61-1.81-.41-.46-1.05-.83-1.91-1.12l-3.14-1.01c-1.37-.43-2.41-1.08-3.11-1.93-.7-.85-1.05-1.85-1.05-2.98,0-1.56.61-2.82,1.82-3.77,1.21-.95,2.77-1.43,4.67-1.43.87,0,1.76.1,2.69.31s1.74.46,2.44.78v3.97h-1.23c-.22-1.01-.71-1.86-1.48-2.56-.77-.7-1.77-1.05-3-1.05-.96,0-1.74.23-2.35.69-.6.46-.9,1.07-.9,1.84,0,.72.19,1.32.56,1.81s.97.87,1.79,1.15l3.65,1.26c2.5.87,3.75,2.48,3.75,4.84,0,1.68-.61,3.03-1.84,4.02-1.23,1-2.85,1.5-4.87,1.5-1.04,0-2.06-.14-3.09-.43Z"
        />
        <path
          className="cls-1"
          d="m810.35,27.43h-9.06v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.32c0-.63-.13-1.06-.38-1.32s-.69-.38-1.32-.38c-.36,0-.83.04-1.41.11v-.9l5.27-1.41h.9v14.22c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Zm-6.46-21.08c-.46-.46-.68-1.02-.68-1.7s.23-1.24.68-1.7c.46-.46,1.02-.68,1.7-.68s1.24.23,1.7.68c.46.46.69,1.02.69,1.7s-.23,1.24-.69,1.7c-.46.46-1.02.69-1.7.69s-1.24-.23-1.7-.69Z"
        />
        <path
          className="cls-1"
          d="m816.96,26.64c-1.35-.82-2.39-1.93-3.14-3.34-.75-1.41-1.12-2.98-1.12-4.71s.37-3.3,1.12-4.71c.75-1.41,1.79-2.52,3.14-3.34,1.35-.82,2.88-1.23,4.58-1.23s3.24.41,4.58,1.23c1.35.82,2.39,1.93,3.14,3.34.75,1.41,1.12,2.98,1.12,4.71s-.37,3.3-1.12,4.71c-.75,1.41-1.79,2.52-3.14,3.34-1.35.82-2.88,1.23-4.58,1.23s-3.24-.41-4.58-1.23Zm9.75-8.05c0-5.13-1.72-7.69-5.16-7.69s-5.16,2.56-5.16,7.69,1.72,7.69,5.16,7.69,5.16-2.56,5.16-7.69Z"
        />
        <path
          className="cls-1"
          d="m853.56,27.43h-8.45v-.9c.94-.15,1.57-.37,1.89-.67s.49-.79.49-1.46v-8.19c0-1.52-.33-2.64-.99-3.38s-1.61-1.1-2.83-1.1c-2.19,0-3.68.9-4.48,2.71v9.53c0,.96.16,1.6.49,1.91s.99.53,2,.65v.9h-8.55v-.9c.84-.07,1.47-.18,1.88-.33.41-.14.7-.38.87-.72.17-.34.25-.84.25-1.52v-10.83c0-.41-.15-.71-.45-.9s-.7-.29-1.21-.29c-.24,0-.76.04-1.55.11v-.9l5.09-1.41h.83v1.98c0,.56-.1,1.3-.29,2.24l.18.04c.48-1.49,1.27-2.65,2.36-3.47,1.09-.82,2.44-1.23,4.02-1.23s2.98.51,3.97,1.52,1.48,2.61,1.48,4.8v8.34c0,.7.08,1.21.25,1.53.17.32.46.56.87.7.41.15,1.03.25,1.88.33v.9Z"
        />
        <path
          className="cls-1"
          d="m869.63,22.27l.87.43c-.39,1.47-1.18,2.7-2.38,3.68-1.2.99-2.68,1.48-4.44,1.48-2.5,0-4.45-.82-5.85-2.47-1.4-1.65-2.09-3.82-2.09-6.52,0-1.83.31-3.46.94-4.91s1.53-2.58,2.73-3.41c1.19-.83,2.62-1.25,4.28-1.25,2.21,0,3.9.7,5.07,2.09,1.17,1.4,1.75,3.25,1.75,5.56h-12.06c.1,2.38.7,4.25,1.82,5.61,1.12,1.36,2.68,2.04,4.68,2.04,2.26,0,3.83-.78,4.69-2.35Zm-9.84-10.18c-.78.89-1.22,2.11-1.32,3.65h8.16c0-1.52-.3-2.72-.88-3.63-.59-.9-1.51-1.35-2.76-1.35-1.35,0-2.41.45-3.19,1.34Z"
        />
        <path
          className="cls-1"
          d="m885.4,9.46v3.61h-.58c-.39-.22-.89-.32-1.52-.32-1.13,0-2.08.34-2.83,1.01s-1.14,1.73-1.14,3.18v7.04c0,.67.11,1.18.32,1.52.22.34.58.58,1.08.72.51.15,1.27.25,2.31.33v.9h-9.78v-.9c.87-.1,1.5-.22,1.9-.36.4-.14.68-.38.85-.7.17-.33.25-.82.25-1.5v-10.32c0-.6-.13-1.03-.38-1.28-.25-.25-.69-.38-1.32-.38l-1.55.07v-.9l5.09-1.41h.83v2.64c0,1.59-.02,2.91-.07,3.97h.22l.29-1.88c.26-1.73.87-3.03,1.82-3.88.95-.85,1.98-1.28,3.09-1.28.48,0,.85.05,1.12.14Z"
        />
      </motion.g>
    </svg>
  );
};

export default TypoSplash;
