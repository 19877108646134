import React from "react";
import Layout from "./src/components/layout";
import { ThemeProvider } from "./src/components/context/theme-context";

import "./src/styles/global.css";

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    <Layout {...props}>{element}</Layout>
  </ThemeProvider>
);
