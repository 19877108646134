import React, { useContext } from "react";

import { motion } from "framer-motion";
import { ThemeContext } from "./context/theme-context";

import TypoSplash from "./typo-splash";

const Splash = () => {
  const { setIsFirstTime } = useContext(ThemeContext);

  return (
    <motion.div
      variants={fade}
      initial="initial"
      animate="animate"
      exit="exit"
      onClick={() => setIsFirstTime(false)}
      className="hover:cursor-pointer bg-gray-100 fixed top-0 left-0 w-full z-[2] flex justify-center items-center h-safe-screen font-serif "
    >
      <div className="px-8 absolute inset-0 grid grid-cols-10 md:grid-cols-20 text-md sm:text-2xl md:text-4xl break-word h-[100%]">
        <div className="col-start-1 col-span-10 md:col-start-3 md:col-span-16 flex items-center ">
          <TypoSplash />
        </div>
      </div>
    </motion.div>
  );
};

export default Splash;

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.7,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.4,
    },
  },
};
